$dark: #122633;
$light: #F0EBE8;
$info: #8eaed5;
$primary: #F8A901;
$link-color: $light;
$link-hover-color: $primary;
$body-bg: $light;

//FONTS

@font-face {
    font-family: 'Diagramm';
    src: url('fonts/Diagramm-Regular.otf');
    font-display: block;
  }
  
  @font-face {
    font-family: "Diagramm";
    src: url("fonts/Diagramm-Bold.otf");
    font-weight: bold;
    font-display: block;
  }
  
  @font-face {
    font-family: "Diagramm";
    src: url("fonts/Diagramm-Light.otf");
    font-weight: 300;
    font-display: block;
  }
  
  @font-face {
    font-family: "Diagramm";
    src: url("fonts/Diagramm-Medium.otf");
    font-weight: 500;
    font-display: block;
  }
  
$font-family-sans-serif: "Diagramm", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: FMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
$font-family-base: $font-family-sans-serif;


@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "customStyles";