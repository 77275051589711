section {
  padding: 2rem 0;
  @include media-breakpoint-up("md") {
    padding: 4.5rem 0;
  }
}

.leaflet-popup-content {
  text-align: center;
  font-size: 13px;
}

.fillViewPort{
  min-height: calc(100vh - 145px);
}


@keyframes example {
  from {
    opacity: 0;
    bottom: 10px;
  }
  to {
    opacity: 1;
    bottom: 0;
  }
}

.animate {
  opacity: 0;
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}


.mainTitle {
  font-size: 43px;
  font-weight: 800;
  font-style: italic;
  // @include media-breakpoint-up("sm") {
  //   font-size: 55px;
  // }

  @include media-breakpoint-up("sm") {
    font-size: 63px;
  }
  @include media-breakpoint-up("xl") {
    font-size: 73px;
  }
}

.subtitle {
  font-size: 25px;
  font-weight: 700;
  font-style: italic;
}



